import React from 'react'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
import { breakpoints, layout } from '../theme'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import Signup, { StyledSignup } from '../components/Signup'
import { StyledProfileDropdown } from '../components/ProfileDropdown'
import Link, { navigate } from '../components/Link'
import { LOGIN } from '../util/links'

class SignupPage extends React.Component {
  render() {
    return (
      <StyledSignupPage>
        <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
        <Navbar />
        <div className="page-content">
          <h2>Créer un compte avec votre adresse email</h2>
          <Signup
            // eslint-disable-next-line no-console
            onSignup={() => console.log()}
            onCancel={() => navigate(-1)}
          />
          <div className="btn-link">
            ou
            <Link to={LOGIN}>Connectez-vous</Link>
          </div>
        </div>
        <Footer />
      </StyledSignupPage>
    )
  }
}

const StyledSignupPage = styled.div`
  .page-content {
    background-color: #f2f5f7;
    margin-top: ${layout.navbarHeight.normal}px;
    min-height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledProfileDropdown} {
    display: none;
  }

  h2 {
    text-align: center;
  }

  ${StyledSignup} {
    width: 100%;
    padding-top: 50px;
  }

  .btn-link {
    margin-top: 30px;
    a {
      margin-left: 10px;
      color: #fc4c4c;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export default SignupPage
