import React, { useState } from 'react'
import styled from 'styled-components'
import { TextInput, Button, StyledButton } from '@monbanquet/crumble'
import { ClientError, httpFetch } from '../util/httpFetch'
import { navigate } from './Link'
import { initiatedScripts, track } from '../analytics'

async function waitForXSeconds(time) {
  return new Promise(resolve => setTimeout(resolve, time * 1000))
}

const getErrorMessage = (message, email, firstName, lastName) => {
  if (message == '1') {
    return <>Le mot de passe doit avoir plus de 8 caractères</>
  } else if (message == '2') {
    return <>Cet email est déjà utilisé</>
  } else {
    return (
      <>
        Une erreur s&apos;est produite, merci de nous contacter en&#8239;
        <span
          className="chat-link"
          onClick={() => erroOpenChat(email, firstName, lastName)}
          onKeyPress={() => erroOpenChat(email, firstName, lastName)}
          role="link"
          tabIndex={0}
        >
          cliquant ici
        </span>
      </>
    )
  }
}

const erroOpenChat = (email, firstName, lastName) => {
  if (process.env.GATSBY_ENV_NAME === 'prod') {
    initiatedScripts.intercom.bootEmail(email, firstName + ' ' + lastName)
    initiatedScripts.intercom.message(
      `Bonjour, je n'arrive pas à créer un compte avec l'email "${email}" au nom de ${firstName} ${lastName}. \nPouvez-vous m'aider ?`,
    )

    track('open-chat-register-error', {
      customerEmail: email,
      customerFirstName: firstName,
      customerLastName: lastName,
      demandLink: window.location.href,
    })
  }
}

async function signup({ email, firstName, lastName, password }) {
  if (!email || !password) {
    throw new ClientError(
      'Vous devez spécifier un email et mot de passe',
      `To get the JWT you need at least an email/password or a refreshJwt`,
    )
  }

  const url = `${process.env.GATSBY_VENUS_BACK_URL}/api/public/auth/register`
  const body = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    password: password,
    nextUrl: '',
  }

  return httpFetch(url, {
    method: 'POST',
    // Allow both CORS and same origin requests to work with cookies. Unfetch won't send or receive cookies otherwise.
    // see https://github.com/developit/unfetch#fetchurl-string-options-object
    noAuth: true, // we don't include the authorization header because the call is public and made to get authorized
    body,
  }).catch(error => {
    if (error.userMessage) {
      throw new Error(error.userMessage)
    }

    throw error
  })
}

const Signup = ({ onSignup, onCancel, ...props }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [confirmMailSent, setConfirmMailSent] = useState(false)
  const [error, setError] = useState()

  return (
    <StyledSignup
      onSubmit={async e => {
        e.preventDefault()

        try {
          const user = await signup({ email, firstName, lastName, password })
          setConfirmMailSent(true)
          setError(null)
          await waitForXSeconds(1)
          onSignup(user)
        } catch (error) {
          console.error(error)
          console.error(error.message)
          setError(getErrorMessage(error.message, email, firstName, lastName))
        }
      }}
      {...props}
    >
      {confirmMailSent ? (
        <>
          <div className="mail-sent">
            <h3>Vous y êtes presque !</h3>

            <p>
              {`Nous avons envoyé un email à `}
              <strong>{email}</strong>
              {` pour valider votre compte.`}
            </p>
            <p>
              {`Veuillez cliquer sur le lien à l'intérieur pour accéder à votre
              espace.`}
            </p>
            <span role="img" aria-label="lettre">
              💌
            </span>
          </div>
        </>
      ) : (
        <>
          <TextInput
            label="Adresse email"
            type="email"
            autoComplete="email"
            secondary
            placeholder="mon@email.com"
            required
            autoFocus
            value={email}
            onChange={emailInput => setEmail(emailInput)}
          />
          <TextInput
            label="Choisir un mot de passe"
            type="password"
            autoComplete="new-password"
            secondary
            required
            value={password}
            onChange={passwordInput => setPassword(passwordInput)}
          />
          <div className="names">
            <TextInput
              className="first-name"
              label="Prénom"
              type="text"
              autoComplete="given-name"
              secondary
              required
              value={firstName}
              onChange={firstNameInput => setFirstName(firstNameInput)}
            />
            <TextInput
              className="last-name"
              label="Nom"
              type="text"
              autoComplete="family-name"
              secondary
              required
              value={lastName}
              onChange={lastNameInput => setLastName(lastNameInput)}
            />
          </div>
        </>
      )}
      <div className="btns">
        <Button onClick={onCancel} className="cancel">
          Annuler
        </Button>
        {confirmMailSent ? (
          <Button onClick={() => navigate(-2)}>Retour à la demande</Button>
        ) : (
          <Button type="submit">Créer un compte</Button>
        )}
      </div>
      {error && <p className="error">{error}</p>}
    </StyledSignup>
  )
}

const StyledSignup = styled.form`
  max-width: 400px;

  .chat-link {
    text-decoration: underline;
    cursor: pointer;
  }

  .error {
    margin-top: 20px;
    color: #fc4c4c;
    text-align: center;
  }

  > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .btns,
  .names {
    display: flex;
  }

  ${StyledButton} {
    border-radius: 4px;
  }

  .first-name {
    width: 100%;
    margin-right: 20px;
  }
  .last-name {
    width: 100%;
    margin-left: auto;
  }

  .cancel {
    background: #aaa;
    margin-right: auto;
  }
  .mail-sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    h3 {
      margin-bottom: 25px;
    }
    p {
      margin-top: 15px;
    }
    span {
      font-size: 40px;
      text-align: center;
      margin: 10px;
      display: block;
    }
  }
`

export default Signup
export { StyledSignup }
